import { Component } from '@angular/core';

@Component({
  selector: 'app-sitemap',
  standalone: true,
  imports: [],
  templateUrl: './sitemap.component.html',
  styleUrl: './sitemap.component.scss'
})
export class SitemapComponent {

}
