<footer class="footer mt-auto py-3 text-light main-footer wf100" style="background-color: #004080;">
  <div class="container">
    <div class="row">
      <!-- Left Column -->
      <div class="col-md-4 mb-3">
        <div style="display: flex;">
          <img src="assets/images/footer/Headerlogo.png" alt="Logo" style="width: 50px; margin-right: 10px;">
          <div style="color: white;">
            <strong>महाराष्ट्र गोसेवा आयोग</strong><br>
            <span>महाराष्ट्र शासन</span>
          </div>
        </div>
        <p class="mt-2">
          पशुसंवर्धन आयुक्तालय,<br>पहिला मजला, स्पायसर कॉलेज समोर, औंध-खडकी रोड, औंध, पुणे-411067</p>
        <!-- <p><i class="fas fa-map-marker-alt"></i> Pune Head Office Address:<br>
            पुणेप्रवाशाचं अहिल्यादेवी महाराष्ट्र मंडल व खोली विकास महामंडळ<br>
            वि. गोसलनगर, पुणे 411016</p> -->
        <p><i class="fa fa-envelope"></i> Email:mhgosevaayog&#64;gmail.com</p>
        <p><i class="fa fa-phone"></i> Call us: <br>020-25657112</p>
      </div>

      <!-- Middle Column -->
      <div class="col-md-4 mb-3">
        <h5>वेबसाइट लिंक्स</h5>
        <ul class="list-unstyled">
          <li><a href="contact-us" class="text-light">संपर्क</a></li>
          <li><a href="about-us" class="text-light">माहिती</a></li>
          <li><a href="gallery" class="text-light">गॅलरी</a></li>
          <li><a href="scheme-1" class="text-light">योजना</a></li>
          <li><a href="https://dahd.maharashtra.gov.in/en/" class="text-light">dahd.maharashtra.gov.in</a></li>
        </ul>
      </div>

      <!-- Right Column -->
      <div class="col-md-4 mb-3">
        <h5>इतर लिंक्स</h5>
        <ul class="list-unstyled">
          <li><a href="#" class="text-light">यूट्यूब व्हिडिओज</a></li>
          <li><a href="privacypolicy" class="text-light">Privacy Policy</a></li>
          <li><a href="sitemap" class="text-light">Sitemap</a></li>
          <li><a href="https://maharashtragosevaayog.com/" class="text-light">maharashtragosevaayog.com</a></li>
        </ul>
        <!-- <div class="social-links">
          <a href="#" class="text-light mx-2"><i class="fa fa-facebook"></i></a>
          <a href="#" class="text-light mx-2"><i class="fa fa-twitter"></i></a>
          <a href="#" class="text-light mx-2"><i class="fa fa-instagram"></i></a>
          <a href="#" class="text-light mx-2"><i class="fa fa-linkedin"></i></a>
          <a href="#" class="text-light mx-2"><i class="fa fa-youtube"></i></a>
        </div> -->
      </div>
    </div>
    <div class="text-center d-flex align-items-center">
      <p class="mb-0 w-100">&copy; Copyright 2024. All Rights Reserved. Site designed by Averta Strategy Pvt. Ltd.</p>
      &nbsp;
    </div>
  </div>
</footer>