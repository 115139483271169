import { CommonModule } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule,RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  constructor(private renderer: Renderer2) {}

  skipToContent() {
    const mainContent = document.querySelector('main');
    if (mainContent) {
      mainContent.scrollIntoView({ behavior: 'smooth' });
      (mainContent as HTMLElement).focus();
    }
  }

  skipToNavigation() {
    const navigation = document.querySelector('nav');
    if (navigation) {
      navigation.scrollIntoView({ behavior: 'smooth' });
      (navigation as HTMLElement).focus();
    }
  }


  toggleGrayScale() {
    document.body.classList.toggle('gray-scale');
  }

  toggleHighContrast() {
    document.body.classList.toggle('high-contrast');
  }

  adjustTextSize(action: string) {
    const root = document.documentElement;
    const currentSize = parseFloat(getComputedStyle(root).fontSize);
    if (action === 'increase') {
      root.style.fontSize = `${currentSize + 2}px`;
    } else if (action === 'decrease') {
      root.style.fontSize = `${currentSize - 2}px`;
    } else if (action === 'reset') {
      root.style.fontSize = '';
    }
  }


  changeLanguage(event: Event) {
    const selectElement = event.target as HTMLSelectElement; // Cast the target to HTMLSelectElement
    const selectedLanguage = selectElement.value;
    console.log(`Selected language: ${selectedLanguage}`);
    // Perform additional logic for language change
  }
  

}
