import { Routes } from '@angular/router';
import { PrivacypolicyComponent } from './modules/privacypolicy/privacypolicy.component';
import { SitemapComponent } from './modules/sitemap/sitemap.component';



export const routes: Routes = [
    {
        path: '',
        loadChildren : () => import('./modules/home/home.module').then(m=>m.HomeModule)

    },

    {
        path:'success-stories',
        loadChildren : () => import('./modules/success-stories/success-stories.module').then(m=>m.SuccessStoriesModule)
    },


    {
        path: 'new-application',
        loadChildren : () => import('./modules/registration/registration.module').then(m=>m.RegistrationModule)
    },
    
    {
        path: 'faq',
        loadChildren : () => import('./modules/faq/faq.module').then(m=>m.FaqModule)
    

    },

    {
        path: 'about-us',
        loadChildren : () => import('./modules/about-us/about-us.module').then(m=>m.AboutUsModule)
    
      
    },

    {
        path: 'contact-us',
        loadChildren : () => import('./modules/contact-us/contact-us.module').then(m=>m.ContactUsModule)
    

    },

    {
        path: 'screen-reader',
        loadChildren : () => import('./modules/screen-reader/screen-reader.module').then(m=>m.ScreenReaderModule)
    
    },

    {
        path: 'gallery',
        loadChildren : () => import('./modules/gallery/gallery.module').then(m=>m.GalleryModule)
    
    },

    {
        path: 'maharashtran-cow',
        loadChildren : () => import('./modules/maharashtran-cow/maharashtran-cow.module').then(m=>m.MaharashtranCowModule)


    },

    {
        path: 'trainings',
        loadChildren : () => import('./modules/trainings/trainings.module').then(m=>m.TrainingsModule)
    },

    {
        path: 'grievance',
        loadChildren : () => import('./modules/grievance/grievance.module').then(m=>m.GrievanceModule)
      

    },

    {
        path: 'scheme-1',
        loadChildren : () => import('./modules/scheme-1/scheme-1.module').then(m=>m.Scheme1Module)
      
    },

    {
        path: 'scheme-2',
        loadChildren : () => import('./modules/scheme-2/scheme-2.module').then(m=>m.Scheme2Module)

    },
    {
        path: 'trainingdetails',
        loadChildren : () => import('./modules/trainingdetails/trainingdetails.module').then(m=>m.TrainingdetailsModule)

    },


    {
        path:'trainings',
        loadChildren : () => import('./modules/trainings/trainings.module').then(m=>m.TrainingsModule)
    },


    {
        path:'donationform',
        loadChildren : () => import('./modules/donationform/donationform.module').then(m=>m.DonationformModule)
    },


    // {
    //     path: 'details',
    //     component: DetailsComponent,

    // },

    // {
    //     path: 'app-yojana-tapshil',
    //     component: Scheme1Component,

    // },
    // {
    //     path: 'app-govarddhan-yojana',
    //     component: Scheme2Component,

    // },

    {
        path: 'our-initiation',
        loadChildren : () => import('./modules/our-initiation/our-initiation.module').then(m=>m.OurInitiationModule)

    },


    
    {
        path: 'csr-fund',
        loadChildren : () => import('./modules/csr-fund/csr-fund.module').then(m=>m.CsrFundModule)

    },

    
    {
        path: 'partnership',
        loadChildren : () => import('./modules/partnership/partnership.module').then(m=>m.PartnershipModule)


    },
   

    {
        path: 'goshalaApplication',
        loadChildren : () => import('./modules/registration/registration.module').then(m=>m.RegistrationModule)


    },


    {
        path : 'maharashtra-goshala',
        loadChildren : () => import('./modules/maharashtra-goshala/maharashtra-goshala.module').then(m=>m.MaharashtraGoshalaModule)
    },


    {
        path: 'privacypolicy',
        component: PrivacypolicyComponent ,

    },


    {
        path: 'sitemap',
        component: SitemapComponent ,

    },
    {
      path: '**',
      redirectTo: ''
    }


];
