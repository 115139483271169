import { ConstantsService } from '../constants/constants.service';

import { HttpRequest, HttpEvent, HttpInterceptorFn, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { inject } from '@angular/core';

declare var $:any;

export const errorHandlerInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  
  const router = inject(Router);
  const constants = inject(ConstantsService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        constants.errorMsg(constants.MESSAGES['UNAUTHORIZED']);
        router.navigate(['login']); 
      } else if(error.status === 403){
        constants.errorMsg(constants.MESSAGES['FORBIDDEN']);
        router.navigate(['dashboard']);
      } else {
        if(error.error && error.error.message){
          constants.errorMsg(error.error.message);
        }else{
          constants.errorMsg(constants.MESSAGES['ERROR']);
        }
      }
      // You can customize error handling for other status codes as needed

      return throwError(() => error);
    })
  );
};