<section class="wf100 subheader1"
    style="background-image: url('assets/yashogatha image/background.jpg'); background-size: cover; background-position: center; background-repeat: no-repeat; padding: 100px 0;">
    <div class="container">
        <h2 style="margin-left: -100px; color: #ffffff; font-size: 30px;">Privacy policy</h2>
        <ul class="breadcrumb">
            <li>
                <a routerLink="/" style="color: rgb(255, 255, 255);margin-left: -100px; font-size: 15px;">Home
                    &nbsp;/
                </a>
            </li>
            <li>
                <a routerLink="/our-initiation" style="color: rgb(255, 255, 255) ;font-size: 15px;">privacy policy</a>
            </li>
        </ul>
    </div>
</section>

<div class="container mt-3">
    <div class="shadow p-3 mb-5 bg-body rounded">
        <h5><b>गोसेवा आयोगाचे गोपनीयता धोरण आणि अटी </b></h5>
        <h6>गोसेवा आयोगांच्या सेवांचा उपयोग करणाऱ्या प्रत्येकासाठी खालील अटी आणि धोरण लागू आहेत. आमच्या
            गोपनीयतेसंदर्भातील
            आणि
            सेवा
            वापरण्यासंबंधीच्या या नियमांचे पालन करणे बंधनकारक आहे.</h6>
        <h5><b>सेवा वापरण्याच्या अटी</b></h5>
        <h6>गोसेवा आयोगाच्या वेबसाइट/मोबाइल अ‍ॅप्लिकेशनचा उपयोग करणाऱ्या प्रत्येक व्यक्तीने खालील अटींना मान्यता
            द्यावी:<br>

            १. गोसेवा आयोगाशी संबंधित माहितीचा उपयोग गायींच्या संरक्षण व संवर्धनाच्या उद्देशाने करावा.<br>
            २. कोणत्याही अनधिकृत क्रियाकलापासाठी वेबसाइटचा उपयोग करू नये.<br>
            ३. सर्व नोंदणीकृत युजर्सना नियमांचे पालन करणे बंधनकारक आहे.</h6>
        <h5><b>अस्वीकरण</b></h5>
        <h6>१. गोसेवा आयोगाच्या वेबसाइटवरील सर्व माहिती फक्त मार्गदर्शनासाठी आहे.<br>
            २. आम्ही माहितीच्या अचूकतेची खात्री देत नाही, आणि त्यावरील अवलंब युजर्सच्या जबाबदारीवर आहे.<br>
            ३. वेबसाइटवरून उपलब्ध असलेल्या कोणत्याही सेवेमुळे होणाऱ्या नुकसानासाठी गोसेवा आयोग जबाबदार नाही.</h6>
        <h5><b> ऑनलाइन संपर्कासाठी नियम</b></h5>
        <h6>१. युजर्सनी गोसेवा आयोगाशी संपर्क करताना प्रामाणिक व स्पष्ट माहिती द्यावी.<br>
            २. अपमानास्पद, चुकीची किंवा अनधिकृत माहिती देणे टाळावे.<br>
            ३. गोसेवा आयोगाशी संबंधित कोणत्याही समस्येबाबत फक्त अधिकृत संपर्क माध्यमांचा वापर करावा.</h6>
        <h5><b>साहित्य वापरण्याची परवानगी</b></h5>
        <h6>१. गोसेवा आयोगाच्या वेबसाइटवरील मजकूर, चित्रे, व डिझाईन यांचा वापर केवळ वैयक्तिक किंवा शैक्षणिक उद्देशांसाठी
            करता
            येईल.<br>
            २. साहित्याचा व्यावसायिक वापर करण्यासाठी गोसेवा आयोगाच्या परवानगीची आवश्यकता आहे.<br>
            ३. अनधिकृत कॉपी करणे, पुनरुत्पादन करणे किंवा वितरण करणे यास मनाई आहे.</h6>
        <h5><b>वापराची मर्यादा</b></h5>
        <h6>१. गोसेवा आयोगाच्या संसाधनांचा वापर गायींच्या कल्याणासाठीच करावा.<br>
            २. वेबसाइट किंवा अ‍ॅप्लिकेशनचा गैरवापर आढळल्यास संबंधित व्यक्तीवर कायदेशीर कारवाई केली जाईल.<br>
            ३. वेबसाइटवरून कोणतीही सेवा मिळवण्यासाठी चुकीच्या मार्गाचा वापर निषिद्ध आहे.</h6>
        <h5><b>इतर वेबसाइट्स/मोबाइल अ‍ॅप्लिकेशन दुवे</b></h5>
        <h6>१. गोसेवा आयोगाच्या वेबसाइटवरून इतर वेबसाइट्सचे किंवा अ‍ॅप्लिकेशन्सचे दुवे दिले जाऊ शकतात.<br>
            २. त्या बाह्य वेबसाइट्सच्या सामग्री किंवा गोपनीयतेसाठी गोसेवा आयोग जबाबदार नाही.<br>
            ३. युजर्सनी त्या बाह्य अ‍ॅप्लिकेशन्सचे धोरण वाचावे.</h6>
        <h5><b>हमीची अस्वीकृती / जबाबदारी मर्यादा</b></h5>
        <h6>१. गोसेवा आयोगाची सेवा "जशी आहे तशी" (as is) उपलब्ध आहे, आणि आम्ही कोणत्याही प्रकारची हमी देत नाही.<br>
            २. सेवांच्या वापरामुळे उद्भवलेल्या कोणत्याही अप्रत्यक्ष, अपघाती, किंवा विशेष हानीसाठी गोशाळा जबाबदार नाही.
        </h6>
        <h5><b>ट्रेडमार्क</b></h5>
        <h6>१. गोसेवा आयोगाचे नाव, लोगो, आणि संबंधित सर्व चिन्हे ही गोसेवा आयोगाच्या मालकीची आहेत.<br>
            २. या ट्रेडमार्कचा कोणताही अनधिकृत वापर गंभीर उल्लंघन मानले जाईल.</h6>
        <h5><b>नुकसान भरपाई</b></h5>
        <h6>१. युजर्सनी गोसेवा आयोगाच्या सेवा वापरण्याच्या प्रक्रियेत त्यांच्या चुकीच्या कृतीमुळे झालेल्या नुकसानाची
            भरपाई
            द्यावी.<br>
            २. यामध्ये कायदेशीर खर्च आणि इतर नुकसानांचा समावेश असेल.</h6>
        <h5><b>तुमचे खाते</b></h5>
        <h6>१. गोसेवा आयोगाच्या सेवांसाठी खाते तयार करणाऱ्या युजर्सनी आपली माहिती अचूक ठेवावी.<br>
            २. खात्याचा गैरवापर झाल्यास गोशाळा जबाबदार राहणार नाही.<br>
            ३. तुमच्या खात्याचा उपयोग फक्त अधिकृत उद्देशांसाठीच करावा.</h6>
        <h5><b>इतर</b></h5>
        <h6>१. गोसेवा आयोगाने दिलेल्या सूचनांचे पालन करणे अनिवार्य आहे.<br>
            २. अटी व नियमांचे उल्लंघन झाल्यास युजर्सचे खाते रद्द केले जाऊ शकते.</h6>
        <h5><b> सामान्य अटी</b></h5>
        <h6>१. या अटींमध्ये वेळोवेळी बदल करण्याचा गोसेवा आयोगाला अधिकार आहे.<br>
            २. अटी व नियमांचे कोणत्याही स्वरूपाचे उल्लंघन आढळल्यास कायदेशीर कारवाई केली जाईल.<br>
            ३. गोसेवा आयोगाच्या सेवा वापरण्याचा अधिकार व्यवस्थापनाच्या निर्णयानुसार दिला जाईल.</h6>
    </div>

</div>

<div class="container mt-3">
    <div class="shadow p-3 mb-5 bg-body rounded">
        <h5><b>Privacy Policy and Terms of GoSeva Commission</b></h5>
        <h6>The following terms and policies apply to everyone using the services of the GoSeva Commission. It is mandatory to adhere to these rules related to our privacy and service usage.</h6>
        <h5><b>Terms of Service</b></h5>
        <h6>Every individual using the website/mobile application of the GoSeva Commission must agree to the following terms:<br>

            1. Use the information related to the GoSeva Commission for the purpose of protection and conservation of cows.<br>
            2. Do not use the website for any unauthorized activities.<br>
            3. All registered users must comply with the rules.</h6>
        <h5><b>Disclaimer</b></h5>
        <h6>1. All information on the GoSeva Commission’s website is for guidance purposes only.<br>
            2. We do not guarantee the accuracy of the information, and reliance on it is at the user's own risk.<br>
            3. The GoSeva Commission is not responsible for any damages caused by services available on the website.</h6>
        <h5><b>Rules for Online Communication</b></h5>
        <h6>1. Users must provide honest and accurate information while contacting the GoSeva Commission.<br>
            2. Avoid providing offensive, false, or unauthorized information.<br>
            3. Use only official communication channels for issues related to the GoSeva Commission.</h6>
        <h5><b>Permission for Use of Content</b></h5>
        <h6>1. Content, images, and designs on the GoSeva Commission’s website can only be used for personal or educational purposes.<br>
            2. Commercial use of content requires prior permission from the GoSeva Commission.<br>
            3. Unauthorized copying, reproduction, or distribution is prohibited.</h6>
        <h5><b>Usage Restrictions</b></h5>
        <h6>1. Resources of the GoSeva Commission must be used only for the welfare of cows.<br>
            2. Misuse of the website or application will result in legal action against the individual.<br>
            3. Using improper means to access any service is strictly prohibited.</h6>
        <h5><b>Links to Other Websites/Mobile Applications</b></h5>
        <h6>1. The GoSeva Commission’s website may contain links to other websites or applications.<br>
            2. The GoSeva Commission is not responsible for the content or privacy policies of external websites.<br>
            3. Users are advised to read the policies of external applications.</h6>
        <h5><b>Disclaimer of Warranty/Limitation of Liability</b></h5>
        <h6>1. Services provided by the GoSeva Commission are available "as is," and no warranties of any kind are provided.<br>
            2. The GoSeva Commission is not liable for any indirect, incidental, or special damages resulting from the use of services.</h6>
        <h5><b>Trademarks</b></h5>
        <h6>1. The name, logo, and all related symbols of the GoSeva Commission are its proprietary trademarks.<br>
            2. Any unauthorized use of these trademarks will be considered a serious violation.</h6>
        <h5><b>Indemnification</b></h5>
        <h6>1. Users must compensate for damages caused by their wrongful actions during the use of the GoSeva Commission’s services.<br>
            2. This includes legal expenses and other damages.</h6>
        <h5><b>Your Account</b></h5>
        <h6>1. Users creating accounts for the GoSeva Commission’s services must keep their information accurate.<br>
            2. The GoSeva Commission will not be responsible for account misuse.<br>
            3. Your account should only be used for authorized purposes.</h6>
        <h5><b>Miscellaneous</b></h5>
        <h6>1. It is mandatory to follow the instructions provided by the GoSeva Commission.<br>
            2. Violation of terms and conditions may result in account cancellation.<br>
            3. The management reserves the right to provide access to services based on their decision.</h6>
        <h5><b>General Terms</b></h5>
        <h6>1. The GoSeva Commission has the right to modify these terms from time to time.<br>
            2. Legal action will be taken for any violation of the terms and conditions.<br>
            3. The right to use the GoSeva Commission’s services will be granted as per the management’s decision.</h6>
    </div>
</div>
