<section class="wf100 subheader1"
    style="background-image: url('assets/yashogatha image/background.jpg'); background-size: cover; background-position: center; background-repeat: no-repeat; padding: 100px 0;">
    <div class="container">
        <h2 style="margin-left: -100px; color: #ffffff; font-size: 30px;">sitemap</h2>
        <ul class="breadcrumb">
            <li>
                <a routerLink="/" style="color: rgb(255, 255, 255);margin-left: -100px; font-size: 15px;">Home
                    &nbsp;/
                </a>
            </li>
            <li>
                <a routerLink="/our-initiation" style="color: rgb(255, 255, 255) ;font-size: 15px;">sitemap </a>
            </li>
        </ul>
    </div>
</section><br>


<div class="container mt-3 ">
    <div class="shadow p-3 mb-5 bg-body rounded">
        <h5>
            <ul>
                <li>मुखपृष्ठ</li><br>
                <li>आमच्याबद्दल</li><br>
                <li>नवीन उपक्रम<br>
                    <ul><br>
                        <li>गो रक्षा - गो दान</li><br>
                        <li>सीएसआर निधी</li><br>
                        <li>सामाजिक संस्थांची भागेदारी</li>
                    </ul>
                </li><br>

                <li> प्रशिक्षणे</li><br>
                <li>गाईच्या प्रजाती</li><br>
                <li>योजना
                    <ul><br>
                        <li>सुधारित गोवर्धन गोवंश सेवा केंद्र योजना</li><br>
                        <li>देशी गाईच्या परिपोषणासाठी प्रति दिन अनुदान योजना</li>
                    </ul><br>
                </li>
                <li>गोशाळा नोंदणी </li><br>
                <li>तक्रार नोंदणी </li><br>
                <li>यशोगाथा</li><br>
                <li>संपर्क</li><br>
                <li>गॅलरी</li><br>
                <li>सामान्य प्रश्न</li><br>
                <li>माहिती</li><br>
                <li>युट्युब व्हिडिओस </li><br>
                <li>privacy policy</li><br>
                <li>sitemap</li><br>
            </ul>
        </h5>
    </div>
</div>