<!-- <nav class="navbar fixed-top navbar-expand-lg navbar-light nav-set"> -->
<!-- <div class="container-fluid" style="--bs-gutter-x: 0rem;">
  <div class="header-im">
    <img src="assets/images/header/Banner - final_new change_oct24 .png" alt="Header Image">
  </div>
</div> -->

<section class="accessibility">
    <div class="accessibility-toolbar d-flex flex-wrap align-items-center justify-content-end p-2">
      <!-- Skip to Main Content -->
      <button class="btn btn-sm btn-outline-light me-2 mb-2" (click)="skipToContent()">Skip to main content</button>
      
      <!-- Skip to Navigation -->
      <button class="btn btn-sm btn-outline-light me-2 mb-2" (click)="skipToNavigation()">Skip to Navigation</button>
      
      <!-- Screen Reader -->
      <a class="btn btn-sm btn-outline-light me-2 mb-2" href="/screen-reader">Screen Reader</a>
      
      <!-- Gray Scale -->
      <button class="btn btn-sm btn-outline-light me-2 mb-2" (click)="toggleGrayScale()">Gray Scale</button>
      
      <!-- High Contrast -->
      <button class="btn btn-sm btn-outline-light me-2 mb-2" (click)="toggleHighContrast()">High Contrast</button>
      
      <!-- Text Size Controls -->
      <div class="d-flex align-items-center flex-wrap mb-2">
        <span class="text-light me-2">Text Size:</span>
        <button class="btn btn-sm btn-outline-light me-2" (click)="adjustTextSize('decrease')">A-</button>
        <button class="btn btn-sm btn-outline-light me-2" (click)="adjustTextSize('reset')">A</button>
        <button class="btn btn-sm btn-outline-light me-2" (click)="adjustTextSize('increase')">A+</button>
      </div>
      
      <!-- Language Selector -->
      <!-- <select 
        class="form-select form-select-sm text-light bg-dark mb-2" 
        style="width: auto;" 
        (change)="changeLanguage($event)">
        <option>Select Language</option>
        <option value="en">English</option>
        <option value="mr">Marathi</option>
      </select> -->
    </div>
  </section>
  
  
  <div class="container-fluid p-0">
    <!-- Banner -->
    <div class="mr-0">
      <img src="assets/images/header/Banner - final_new change_oct24 .png" alt="Banner" class="img-fluid w-100">
    </div>
  
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #10437A;">
      <div class="container">
        <!-- Brand -->
        <a routerLink="/webui/home" class="navbar-brand text-white">
          <strong>महाराष्ट्र गोसेवा आयोग</strong>
        </a>
  
        <!-- Toggler Button -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" 
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <!-- Navbar Links -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mx-auto">
            <!-- Main Page -->
            <li class="nav-item">
              <a class="nav-link text-white" routerLink="/" routerLinkActive="active-link">मुख्यपृष्ठ</a>
            </li>
            <!-- About Us -->
            <li class="nav-item">
              <a class="nav-link text-white" routerLink="about-us" routerLinkActive="active-link">आमच्याबद्दल</a>
            </li>
            <!-- New Initiatives Dropdown -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle text-white" id="ourInitiationDropdown" role="button" 
                data-bs-toggle="dropdown" aria-expanded="false">
                नवीन उपक्रम
              </a>
              <ul class="dropdown-menu" aria-labelledby="ourInitiationDropdown">
                <li><a class="dropdown-item" routerLink="our-initiation" routerLinkActive="active-link">गो रक्षा - गो दान</a></li>
                <li><a class="dropdown-item" routerLink="csr-fund" routerLinkActive="active-link">सी एस आर निधी</a></li>
                <li><a class="dropdown-item" routerLink="partnership" routerLinkActive="active-link">सामाजिक संस्थांची भागेदारी</a></li>
              </ul>
            </li>
            <!-- Trainings -->
            <li class="nav-item">
              <a class="nav-link text-white" routerLink="trainings" routerLinkActive="active-link">प्रशिक्षणे</a>
            </li>
            <!-- Cow Breeds -->
            <li class="nav-item">
              <a class="nav-link text-white" routerLink="maharashtran-cow" routerLinkActive="active-link">गायींच्या प्रजाती</a>
            </li>
            <!-- Schemes Dropdown -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle text-white" id="yojanaDropdown" role="button" 
                data-bs-toggle="dropdown" aria-expanded="false">
                योजना
              </a>
              <ul class="dropdown-menu" aria-labelledby="yojanaDropdown">
                <li><a class="dropdown-item" routerLink="scheme-2" routerLinkActive="active-link">सुधारित गोवर्धन गोवंश सेवा केंद्र योजना</a></li>
                <li><a class="dropdown-item" routerLink="scheme-1" routerLinkActive="active-link">देशी गायींच्या परिपोषणासाठी प्रति दिन अनुदान योजना</a></li>
              </ul>
            </li>
            <!-- Additional Links -->
            <li class="nav-item"><a class="nav-link text-white" routerLink="new-application" routerLinkActive="active-link">गोशाळा नोंदणी</a></li>
            <li class="nav-item"><a class="nav-link text-white" routerLink="grievance" routerLinkActive="active-link">तक्रार नोंदणी</a></li>
            <li class="nav-item"><a class="nav-link text-white" routerLink="success-stories" routerLinkActive="active-link">यशोगाथा</a></li>
            <li class="nav-item"><a class="nav-link text-white" routerLink="contact-us" routerLinkActive="active-link">संपर्क</a></li>
            <li class="nav-item"><a class="nav-link text-white" routerLink="gallery" routerLinkActive="active-link">गॅलरी</a></li>
            <li class="nav-item"><a class="nav-link text-white" routerLink="faq" routerLinkActive="active-link">सामान्यप्रश्न</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  